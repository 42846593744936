* {
  margin: 0px;
  padding: 0px;
}

body {
    font-family: 'Montserrat', serif;
  font-weight: 400;
  font-style: normal;
}

.img-con-header {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 0px;
}

.link-con {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.img-con {
    text-align: center;
    margin-bottom: 10px;
}

.video-con {
    margin: 0 auto;
    width: 98%;
}

.video-title {
  cursor: pointer;
  text-decoration: underline;
}

.playlist-videos-con::-webkit-scrollbar,
.playlist-videos-con::-webkit-scrollbar-thumb,
.playlist-videos-con::-webkit-scrollbar-track {
    width: 32px;
    border: none;
    background: black;
}

.playlist-videos-con::-webkit-scrollbar-thumb {
  background-color: #ff71ee;
  border-radius: 16px;
}

.player-con {
  display: inline-block;
  width: 75%;
}

.playlist-videos-con {
    display: inline-block;
    width: 25%;
    margin-top: 0px;
}

.hover-img {
  /* display: none; */
  width: 600px;
  color: #000;
  position: absolute;
  visibility: hidden;
}

.alb-img {
  width: 600px;
}

.vjs-poster {
  background-size: 100% !important;
}

.custom-table> :not(caption)>*>* {
    background-color: black;
    color: #ff71ee;
    font-size: 18px;
    /* text-align: center; */
}

.rowSelected {
  background-color: #6b73f6;
  color: #000;
  width: initial;
  /* text-align: center; */
}

.grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
  width: 100%;
}

.plistInfo {
  padding: 10px;
  background-color: #333;
  text-align: center;
}

.plistInfo>img {
    margin-bottom: 10px;
    width: 100%;
}

.plistInfo>a>button {
    background-color: #ff71ee;
    color: #000;
    font-weight: 600;
}

.btn:hover {
  background-color: #fff;
  color: #ff71ee;
}

a {
  color: #fff;
}

@media screen and (max-width: 800px) {
    .imgBtn {
        width: 40%;
    }

    .imgLogoVA {
        width: 20%;
    }

    .imgLogo {
        width: 67%;
    }

  .grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .link-col {
    width: 40px;
  }

  .alb-img {
    width: 100%;
  }

  .hover-img {
    width: 70%;
    position: fixed;
    border-radius: 5px;
    /* margin: 0; */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

    .player-con {
        display: block;
        width: 100%;
    }

    .playlist-videos-con {
        display: block;
        width: 100%;
        background-color: #000;
        margin-top: 24px;
    }

    .playlist-videos-con>div {
        text-align: center;
    }

    .rowSelected {
        margin: 0 auto;
    }

    .vjs-fixed-box {
        position: fixed;
        background-color: #000;
        padding: 2px;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10;
    }
}

@font-face {
  font-family: EagleBold;
  src: url("./assets/fonts/Eagle-Bold.otf");
}

@font-face {
  font-family: Montserrat;
  src: url("./assets/fonts/Montserrat-Medium.ttf");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: Montserrat;
  src: url("./assets/fonts/Montserrat-Black.ttf");
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: Montserrat;
  src: url("./assets/fonts/Montserrat-BlackItalic.ttf");
  font-style: italic;
  font-weight: 900;
}

@font-face {
  font-family: Montserrat;
  src: url("./assets/fonts/Montserrat-Bold.ttf");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: Montserrat;
  src: url("./assets/fonts/Montserrat-BoldItalic.ttf");
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: Montserrat;
  src: url("./assets/fonts/Montserrat-ExtraBold.ttf");
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: Montserrat;
  src: url("./assets/fonts/Montserrat-ExtraBoldItalic.ttf");
  font-style: italic;
  font-weight: 800;
}

@font-face {
  font-family: Montserrat;
  src: url("./assets/fonts/Montserrat-ExtraLight.ttf");
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: Montserrat;
  src: url("./assets/fonts/Montserrat-ExtraLightItalic.ttf");
  font-style: italic;
  font-weight: 200;
}

@font-face {
  font-family: Montserrat;
  src: url("./assets/fonts/Montserrat-Italic.ttf");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: Montserrat;
  src: url("./assets/fonts/Montserrat-Light.ttf");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: Montserrat;
  src: url("./assets/fonts/Montserrat-LightItalic.ttf");
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: Montserrat;
  src: url("./assets/fonts/Montserrat-MediumItalic.ttf");
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: Montserrat;
  src: url("./assets/fonts/Montserrat-Regular.ttf");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: Montserrat;
  src: url("./assets/fonts/Montserrat-SemiBold.ttf");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: Montserrat;
  src: url("./assets/fonts/Montserrat-SemiBoldItalic.ttf");
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: Montserrat;
  src: url("./assets/fonts/Montserrat-Thin.ttf");
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: Montserrat;
  src: url("./assets/fonts/Montserrat-ThinItalic.ttf");
  font-style: italic;
  font-weight: 100;
}

.font-eagle-bold {
  font-family: EagleBold;
}

.font-semibold {
  font-weight: 600;
}

.embed-container {
  position: relative;
  display: grid;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  border: none;
  grid-template-rows: 20% auto auto;
  background-color: #000;
}

@media (min-width: 768px) {
  .embed-container {
    grid-template-rows: 100%;
    grid-template-columns: 50% 50%;
  }
}

.embed-container > .logo-image-sm {
  width: 100%;
  text-align: center;
  height: 100%;
  padding: 8px;
}

@media (min-width: 768px) {
  .embed-container > .logo-image-sm {
    display: none;
  } 
}

.embed-container > .logo-image-sm > img {
  width: auto;
  max-height: 100%;
}

.embed-player {
  width: 100%;
  overflow: auto;
  display: flex;
  align-items: center;
}

@media (min-width: 768px) {
  .embed-player {
    display: block;
  } 
}

@media (min-width: 768px) {
  .embed-player > .vjs_video_3-dimensions.vjs-fluid {
    padding-top: 51.25%;
  }

  .embed-player > .vjs_video_3-dimensions.vjs-fluid > .vjs_video_3_ima-ad-container {
    overflow: hidden;
  }
}

.embed-container > .embed-footer {
  background-color: #000;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
}

.embed-container > .embed-footer > .embed-info > .logo-image {
  display: none;
}

.embed-container > .embed-footer > .embed-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #000;
  align-items: center;
  width: 100%;
  max-height: 100%;
  overflow: hidden;
  padding: 8px;
  gap: 8px;
}

.embed-container > .embed-footer > .embed-info > .embed-campaign-image {
  width: 100px;
  height: 100px;
  background-color: grey;
  border-radius: 50%;
}

.embed-container > .embed-footer > .embed-info > span {
  color: #ff71ee;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

@media (min-width: 768px) {
  .embed-container > .embed-footer > .embed-info {
    justify-content: space-between;
  }

  .embed-container > .embed-footer > .embed-info > .logo-image {
    display: block;
    width: 100%;
    max-height: 20%;
  }
}

.embed-container > .embed-footer > .embed-playlist > img {
  width: 100%;
  height: 100%;
}
